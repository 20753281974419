import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION } from "../enum";
import { tableData } from "./tableInfo";
import DatePicker from "react-datepicker";
import Factor from "containers/Factor";
// import { useReactToPrint } from "react-to-print";
import exportFromJSON from "export-from-json";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import isoToDate from "utils/isoToDate";
import DeliveriesSelector from "containers/Deliveries/DeliveriesSelector";
import cLog from "utils/cLog";
import { Button, Modal } from "react-bootstrap";
import { fieldNames, validation } from "./validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BranchModal from "./../../../components/BranchModal/index";
import ShowModal from "./component/ShowModal";
import secondsToHms from "utils/secondsToHms";
import NoteForStatusCancel from "./component/NoteForStatusCancel";
import moment from "moment";

const List = () => {
	const { t } = useTranslation();

	const [checkList, setCheckList] = useState();
	const [showModal, setShowModal] = useState(false);
	const [modalStatusCancel, setModalStatusCancel] = useState({
		show: false,
	});
	const [show, setShow] = useState(false);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [currentEditingOrder, setCurrentEditingOrder] = useState("");
	const adminState = useSelector((state) => state.admin);
	const validPermission = adminState?.data?.permissions === true;
	const permissions = validPermission || adminState?.data?.permissions?.[PERMISSION];
	const statisticsPermissions = validPermission || adminState?.data?.permissions?.["statistics"];
	const isBranch = adminState.data.userType === "branch";
	const isHotel = adminState.data.userType === "hotel";
	const isAdmin = adminState.data.userType?.includes("admin");
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);
	// ---------------------------------------------- Constance
	// const button = { title: `Add ${COMPONENT_NAMES[0]}`, link: newRoute.base + newRoute.add };
	const QueryString = useQueryString({ sort: "desc" });
	const { page, limit } = QueryString;
	const dispatch = useDispatch();

	// ---------------------------------------------- fetching functions
	const getting = async () => await axiosClient().get(api[API_NAME].list, { params: { ...QueryString } });
	const gettingStatistics = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].statistics.v1, {
			params: { from, to },
		});
	};
	const gettingStatisticsV2 = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].statistics.v2, {
			params: { from, to },
		});
	};

	const gettingStatisticHotels = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].statistics.hotel, {
			params: { from, to },
		});
	};

	const gettingStatisticRooms = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].statistics.room, {
			params: { from, to },
		});
	};

	const gettingOrders = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].products, {
			params: { from, to },
		});
	};

	const DeliveryReport = async () => {
		const { from, to } = QueryString;
		return await axiosClient().get(api[API_NAME].deliveryReport, {
			params: { from, to },
		});
	};

	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		const data = {
			paymentType: params?.data?.paymentType,
			delivery: params?.delivery,
			status: params.status,
			...(params.note && { cancelNote: params.note }),
		};
		return await axiosClient().patch(api[API_NAME].base + "/" + params?.data?.id, data);
		// await axiosClient().patch(api[API_NAME].changeStatus, params);
	};
	const unassign = async (params) => {
		console.log({ params }, "params");

		return await axiosClient().patch("/v1/admin/orders/unassign-delivery/" + params?.data?.id);
		// await axiosClient().patch(api[API_NAME].changeStatus, params);
	};
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
		enabled: !!(validPermission || permissions?.list),
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessUnassignMutation = () => {
		toastify.success({ title: "success" });
	};

	const unassignMutation = useMutationCustom({
		name: `${API_NAME}_Unassign`,
		url: unassign,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessUnassignMutation,
	});
	// ---------------------------------------------- PRINT

	// const handlePrint = ({ params }) => useReactToPrint({
	//   content: <Factor {...{ params }} />,
	//   // documentTitle: "AwesomeFileName",
	//   // onBeforeGetContent: handleOnBeforeGetContent,
	//   // onBeforePrint: handleBeforePrint,
	//   // onAfterPrint: handleAfterPrint,
	//   removeAfterPrint: true
	// });
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const modalStyles = {
		position: "fixed",
		top: "50%",
		left: "50%",
		transform: `translate(-50%, -50%)`,
		backgroundColor: "white",
		width: "300px",
		height: "300px",
		borderRadius: "5px",
		padding: "20px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	};

	const actionsOperation = ({ data, status, note }) => {
		console.log("action statu ===>", status);

		console.log("action isOpen ===>", showModal);
		{
			if (status.value === "on_way") {
				setShowModal(true);
				setCurrentEditingOrder(data.id);
			} else {
				setShowModal(false);
				setCurrentEditingOrder("");
				swal({
					title: "Are you sure?",
					text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
					icon: "warning",
					buttons: true,
					dangerMode: true,
				}).then((willBe) => {
					if (willBe) {
						changeStatusMutation.mutate({ data, status: status.value, note });
						if (status.value === "payment_success") {
							console.log("triggred");
						}
					}
				});
			}
		}
	};

	const onWayStatusAction = () => {
		const { delivery } = getValues();
		const requestData = {
			status: "on_way",
			delivery: delivery.value,
			data: {
				id: currentEditingOrder,
			},
		};
		console.log("requestData", { requestData });
		changeStatusMutation.mutate(requestData);
		setShowModal(false);
	};

	const handleUnassign = (data) => {
		console.log({ data });
		swal({
			title: "Are you sure?",
			text: `You want unassign this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				unassignMutation.mutate({ data });
				// if (status.value === "payment_success") {
				//   console.log("triggred");
				// }
			}
		});
	};

	const clickAction = ({ data, status, key }) => {
		// if (status.label === "print") return handlePrint(data)
		// const newData = data.map((x) => x.id);
		if (status?.value === "canceled") {
			setModalStatusCancel({ data, status: status, show: true });
		} else if (key == "unassign") handleUnassign(data);
		else actionsOperation({ data: data, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		// console.log({ data, status });
		actionsOperation({ data, status: status });
	};

	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		// XLSX.utils.book_append_sheet(workbook, worksheet, "tab2");
		// XLSX.writeFile(workbook, (fileName || "excel_export") + ".xlsx");
		XLSX.writeFile(workbook, (fileName || "excel_export") + "_" + adminState?.data?.name + "_" + adminState?.data?.lastName + ".xlsx");

		// const ws = XLSX.utils.json_to_sheet(apiData);
		// /* custom headers */
		// XLSX.utils.sheet_add_aoa(ws, [head], { origin: "A1", });
		// const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		// const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		// const data = new Blob([excelBuffer], { type: fileType });
		// FileSaver.saveAs(data, fileName + fileExtension);
	};

	const handleGetFullDataWithDate = async ({ callbackData } = {}) => {
		let cols = [
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
			{ wch: 20 },
		];

		return await axiosClient()
			.get(api[API_NAME].fullData, {
				params: { ...QueryString, limit: 0, page: 1 },
			})
			.then((x) => {
				console.log({ x });
				const head = [
					t("name"),
					t("orderNumber"),
					t("roomNumber"),
					t("paymentType"),
					t("status"),
					t("totalPrice"),
					t("hotelName"),
					// t("suppliers"),
					t("createdAt"),
				];
				const data = x?.data?.map((y) => ({
					[t("name")]: y?.name,
					[t("orderNumber")]: y?.orderNumber,
					[t("roomNumber")]: y?.roomNumber,
					[t("paymentType")]: y?.paymentType,
					[t("status")]: y?.status,
					[t("totalPrice")]: y?.totalPrice,
					[t("hotelName")]: y?.hotelName,
					// [t("suppliers")]: y?.branch.panelName,
					[t("createdAt")]: isoToDate(y.createdAt),
				}));
				console.log("data =====>", data);

				if (callbackData) return { data, head, cols, name: t("static-orders") };
				else exportToCSV({ apiData: data, fileName: t("static-orders"), head, cols });
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const handleGetStatistics = () => {
		let cols = [
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 20 },
			// { wch: 20 },
			// { wch: 20 },
			// { wch: 20 },
			// { wch: 20 },
		];
		gettingStatistics()
			.then((x) => {
				console.log({ x });

				const head = [
					// "otel_uid",
					t("hotelNames"),
					t("scanCount"),
					t("orderCount"),
					// "suppliers",
					// "magnet_scan",
					// "magnet_siparis",
					// "katalog_ziyaret",
					// "katalog_siparis",
					// "toplam_siparis_adedi",
					// "toplam_ziyaret_adedi",
				];
				const data = x?.data?.map((y) => ({
					// otel_uid: y?.uuid,
					[t("hotelNames")]: y?.name,
					[t("scanCount")]: y?.totalView,
					[t("orderCount")]: y?.totalOrder,
					// suppliers: y?.branchName,
					// magnet_siparis: y?.totalMagnetOrder,
					// magnet_scan: y?.totalMagnetView,
					// katalog_ziyaret: y?.totalCatalogView,
					// katalog_siparis: y?.totalCatalogOrder,
					// toplam_siparis_adedi:
					//   y?.totalCatalogOrder + y?.totalMagnetOrder + y?.totalOrder,
					// toplam_ziyaret_adedi:
					//   y?.totalCatalogView + y?.totalMagnetView + y?.totalView,
				}));
				exportToCSV({
					apiData: data,
					fileName: `${t("statics")}-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}`,
					head,
					cols,
				});
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const handleGetStatisticsV2 = () => {
		let cols = [{ wch: 30 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
		gettingStatisticsV2()
			.then((x) => {
				console.log({ x });

				const head = [t("hotelName"), t("regionName"), t("roomCount"), t("type"), t("successful"), t("date"), t("clock")];
				const data = x?.data?.map((y) => {
					const date = moment(y?.createdAt);
					return {
						[t("hotelName")]: y?.hotelName,
						[t("regionName")]: y?.regionName,
						[t("roomCount")]: y?.roomNumber,
						[t("type")]: y?.source,
						[t("successful")]: String(y?.isOrderSubmitted),
						[t("date")]: date.format("YYYY-MM-DD"),
						[t("clock")]: date.format("HH:mm:ss"),
					};
				});
				exportToCSV({
					apiData: data,
					fileName: `${t("staticsDetailed")}-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}`,
					head,
					cols,
				});
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const handleGetStatisticRooms = async () => {
		try {
			const response = await gettingStatisticRooms();
			const data = response?.data;
			const wb = XLSX.utils.book_new();

			const headerRow = [t("hotel"), t("rooms"), "", ""];
			const subHeaderRow = ["", t("roomNumber"), t("quantity"), t("orderCount")];

			const rows = [headerRow, subHeaderRow];

			data.forEach((hotel) => {
				hotel.rooms.forEach((room, index) => {
					const roomRow = [index === 0 ? hotel.hotel : "", room.roomNumber, room.quantity, room.orderCount];
					rows.push(roomRow);
				});
			});

			const ws = XLSX.utils.aoa_to_sheet(rows);

			ws["!merges"] = [{ s: { r: 0, c: 1 }, e: { r: 0, c: 3 } }];
			ws["!cols"] = [{ wch: 30 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];

			ws["B1"].s = { alignment: { horizontal: "center" } };

			ws["!ref"] = XLSX.utils.encode_range({
				s: { r: 0, c: 0 },
				e: { r: rows.length - 1, c: 3 },
			});

			XLSX.utils.book_append_sheet(
				wb,
				ws,
				`${t("static-rooms")}-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}` +
					"_" +
					adminState?.data?.name +
					"_" +
					adminState?.data?.lastName +
					".xlsx"
			);

			XLSX.writeFile(
				wb,
				`${t("static-rooms")}-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}` +
					"_" +
					adminState?.data?.name +
					"_" +
					adminState?.data?.lastName +
					".xlsx"
			);
		} catch (error) {}
		// let cols = [{ wch: 30 }, { wch: 15 }, { wch: 15 }];
		// gettingStatisticRooms()
		// 	.then((x) => {
		// 		console.log({ x });

		// 		const workbook = XLSX.utils.book_new();

		// 		const head = [t("rooms-scans-orders"), t("scan-count"), t("orderCount")];
		// 		x?.data?.map((y) => {
		// 			let apiData = y?.rooms?.map((z) => ({
		// 				[t("rooms-scans-orders")]: z?.roomNumber,
		// 				[t("scan-count")]: z?.quantity,
		// 				[t("orderCount")]: z?.orderCount,
		// 			}));

		// 			const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });
		// 			const range = XLSX.utils.decode_range(worksheet["!ref"]);
		// 			range.e["c"] = head.length - 1;
		// 			worksheet["!ref"] = XLSX.utils.encode_range(range);
		// 			worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		// 			worksheet["!cols"] = cols;

		// 			let limitedString = y?.hotel?.length > 30 ? y?.hotel?.substring?.(0, 30) : y?.hotel;
		// 			XLSX.utils.book_append_sheet(workbook, worksheet, limitedString);
		// 		});

		// 		XLSX.writeFile(
		// 			workbook,
		// 			`statistic-rooms-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}` +
		// 				"_" +
		// 				adminState?.data?.name +
		// 				"_" +
		// 				adminState?.data?.lastName +
		// 				".xlsx"
		// 		);
		// 	})
		// 	.catch((err) => {
		// 		console.log({ err });
		// 	});
	};

	const handleGetStatisticHotels = async ({ callbackData } = {}) => {
		let cols = [{ wch: 30 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
		return await gettingStatisticHotels()
			.then((x) => {
				console.log({ x });

				const head = [t("hotelName"), t("orderCount"), t("scanCount"), t("regionName")];
				const data = x?.data?.map((y) => ({
					[t("hotelName")]: y?.hotelName,
					[t("orderCount")]: y?.orderCount,
					[t("scanCount")]: y?.scanCount,
					[t("regionName")]: y?.regionName,
				}));

				if (callbackData) return { data, head, cols, name: t("static-hotels") };
				else
					exportToCSV({
						apiData: data,
						fileName: `${t("static-hotels")}-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}`,
						head,
						cols,
					});
			})
			.catch((err) => {
				console.log({ err });
			});
	};
	//handleGetDetailedOrdersWithDate

	const handleGetDetailedOrdersWithDate = async ({ callbackData } = {}) => {
		let cols = [
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 20 },
			{ wch: 10 },
			{ wch: 10 },
			{ wch: 10 },
			{ wch: 10 },
			{ wch: 15 },
			{ wch: 10 },
			{ wch: 15 },
			{ wch: 30 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 15 },
		];
		return await gettingOrders()
			.then((x) => {
				console.log({ x });

				let head = [
					t("productId"),
					t("branch"),
					t("hotelNames"),
					t("orderNumber"),
					t("status"),
					t("paymentType"),
					t("quantity"),
					t("tax"),
					t("buyPrice"),
					// t("totalBuyPrice"),
					t("totalPrice"),
					t("productName"),
					t("integrationCode"),
					t("date"),
					t("clock"),
				];

				// admin

				// branch

				if (isHotel) {
					cols = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 30 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
					head = [t("orderNumber"), t("status"), t("paymentType"), t("quantity"), t("totalPrice"), t("productName"), t("date"), t("clock")];
				}
				if (isBranch) {
					cols = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 30 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
					head = [t("branch"), t("orderNumber"), t("status"), t("quantity"), t("buyPrice"), t("productName"), t("date"), t("clock")];
				}

				const data = x?.data?.map((y) => {
					const date = moment(y?.createdAt);
					if (isBranch) {
						return {
							[t("branch")]: y?.branch.name,
							[t("orderNumber")]: y?.orderNumber,
							[t("status")]: y?.status,
							[t("quantity")]: y?.quantity,
							[t("buyPrice")]: y?.buyPrice,
							[t("productName")]: y?.productName,
							[t("date")]: date.format("YYYY-MM-DD"),
							[t("clock")]: date.format("HH:mm:ss"),
						};
					}
					if (isHotel)
						return {
							[t("orderNumber")]: y?.orderNumber,
							[t("status")]: y?.status,
							[t("paymentType")]: y?.paymentType,
							[t("quantity")]: y?.quantity,
							[t("totalPrice")]: y?.price,
							[t("productName")]: y?.productName,
							[t("date")]: date.format("YYYY-MM-DD"),
							[t("clock")]: date.format("HH:mm:ss"),
						};
					return {
						[t("productId")]: y?.id,
						[t("branch")]: y?.branch.name,
						[t("hotelNames")]: y?.hotelName,
						[t("orderNumber")]: y?.orderNumber,
						[t("status")]: y?.status,
						[t("paymentType")]: y?.paymentType,
						[t("quantity")]: y?.quantity,
						[t("kdv")]: y?.kdv,
						[t("totalPrice")]: y?.price,
						[t("buyPrice")]: y?.buyPrice,
						// totalBuyPrice: y?.buyPrice * y?.quantity,
						[t("productName")]: y?.productName,
						[t("integrationCode")]: y?.productIntegrationCode,
						[t("date")]: date.format("YYYY-MM-DD"),
						[t("clock")]: date.format("HH:mm:ss"),
					};
				});
				if (callbackData) return { data, head, cols, name: t("order-product-report") };
				else
					exportToCSV({
						apiData: data,
						fileName: `${t("order-product-report")}-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}`,
						head,
						cols,
					});
			})
			.catch((err) => {
				console.log({ err });
			});
	};

	const handleGetDeliveryReportWithDate = async ({ callbackData } = {}) => {
		return await DeliveryReport()
			.then((x) => {
				console.log({ x });

				const head = [
					t("deliveryName"),
					t("orderNumber"),
					t("region"),
					t("guestName"),
					t("hotelName"),
					t("roomNumber"),
					t("totalPrice"),
					t("totalPriceTry"),
					t("paymentType"),
					t("status"),
					t("createdAt"),
					t("assignedAt"),
					t("onWayAt"),
					t("assignmentDuration"),
					t("rideDuration"),
					t("deliveryDuration"),
					t("preparingDuration"),
					t("deliveredAt"),
					t("productsBuyed"),
				];

				let cols = [
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
					{ width: 20 },
				];

				let calcAverage = {
					delivered: 0,
					assignmentDuration: 0,
					rideDuration: 0,
					deliveryDuration: 0,
				};

				const data = x?.data?.map((y) => {
					if (y?.status == "delivered") {
						calcAverage.delivered++;
						calcAverage.rideDuration += y?.rideDuration;
						calcAverage.assignmentDuration += y?.assignmentDuration;
						calcAverage.deliveryDuration += y?.deliveryDuration;
					}

					return {
						[t("deliveryName")]: y?.delivery || "-",
						[t("orderNumber")]: y?.orderNumber || "-",
						[t("guestName")]: y?.name || "-",
						[t("hotelName")]: y?.hotel?.name || "-",
						[t("roomNumber")]: y?.roomNumber || "-",
						// // [t("scannedRoom")]: y?.scannedRoom || "-",
						[t("totalPrice")]: y?.totalPrice || "-",
						[t("totalPriceTry")]: y?.totalPriceTry || "-",
						[t("paymentType")]: y?.paymentType || "-",
						[t("status")]: y?.status || "-",
						[t("regionName")]: y?.region?.name || "-",
						[t("createdAt")]: y?.createdAt ? isoToDate(y.createdAt) : "-",
						[t("assignedAt")]: y?.assignedAt ? isoToDate(y?.assignedAt) : "-",
						[t("onWayAt")]: y?.onWayAt ? isoToDate(y?.onWayAt) : "-",
						[t("assignmentDuration")]: y?.assignmentDuration ? secondsToHms(y?.assignmentDuration) : "-",
						[t("rideDuration")]: y?.rideDuration ? secondsToHms(y?.rideDuration) : "-",
						[t("deliveryDuration")]: y?.deliveryDuration ? secondsToHms(y?.deliveryDuration) : "-",
						[t("preparingDuration")]: y?.preparingDuration ? secondsToHms(y?.preparingDuration) : "-",
						[t("deliveredAt")]: y?.deliveredAt ? isoToDate(y?.deliveredAt) : "-",
						[t("productsBuyed")]: y?.products?.map((z) => z?.name)?.join(","),
					};
				});

				data.push({
					[t("deliveryName")]: "",
					[t("orderNumber")]: "",
					[t("guestName")]: "",
					[t("hotelName")]: "",
					[t("roomNumber")]: "",
					// [t("scannedRoom")]: "",
					[t("totalPrice")]: "",
					[t("totalPriceTry")]: "",
					[t("paymentType")]: "",
					[t("status")]: "",
					[t("regionName")]: "",
					[t("createdAt")]: "",
					[t("assignedAt")]: "",
					[t("onWayAt")]: "",
					[t("assignmentDuration")]: secondsToHms(calcAverage.assignmentDuration / calcAverage.delivered),
					[t("rideDuration")]: secondsToHms(calcAverage.rideDuration / calcAverage.delivered),
					[t("deliveryDuration")]: secondsToHms(calcAverage.deliveryDuration / calcAverage.delivered),
					[t("preparingDuration")]: secondsToHms(calcAverage.preparingDuration / calcAverage.delivered),
					[t("deliveredAt")]: "",
					[t("productsBuyed")]: "",
				});
				console.log("* * * handleGetDeliveryReportWithDate : ", {
					data,
					calcAverage,
				});

				if (callbackData) return { data, head, cols, name: t("delivery-report") };
				else
					exportToCSV({
						apiData: data,
						fileName: `${t("delivery-report")}-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}`,
						head,
						cols,
					});
			})
			.catch((err) => {
				console.log({ err });
			});
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: t(COMPONENT_NAMES[0]), link: newRoute.base },
		{ title: isAdmin ? t("list") : t("details") },
	];
	// ----------------------------------------------------------------------------- Form
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
		reset,
	} = useForm({
		resolver: yupResolver(validation()),
		mode: "onSubmit",
	});
	// ---------------------------------------------- EFFECTS

	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
		refetch();

		// const data = [
		//   {
		//     otel_uid: "0",
		//     unvan: "0",
		//     toplam_ziyaret_sayisi: "0",
		//     toplam_siparis_adet: "0",
		//     katalog_ziyaret_adedi: "0",
		//     katalog_siparis_adedi: "0",
		//   },
		//   {
		//     otel_uid: "2",
		//     unvan: "3",
		//     toplam_ziyaret_sayisi: "3",
		//     toplam_siparis_adet: "0",
		//     katalog_ziyaret_adedi: "0",
		//     katalog_siparis_adedi: "0",
		//   },
		// ];

		// exportToCSV({ apiData: data, fileName: "aa", head })

		// const fileName = 'download'
		// const exportType = 'xls'   //exported type could be text, json, csv, xls, xml
		// exportFromJSON({ data, fileName, exportType ,})
	}, []);

	const tableOptions = tableData({
		onClick: clickAction,
		newRoute,
		isBranch,
		isHotel,
		isAdmin,
		disable: {
			delete: true,
			...(!validPermission && {
				edit: !permissions?.edit,
				unassignDelivery: !permissions?.unassignDelivery,
				products: !permissions?.delete,
			}),
		},

		active: {
			print: true,
			Component: Factor,
			manuel: {
				icon: "mdi mdi-eye",
				class: "bg-danger",
				onClick: (data) => setShowInfoModal(data),
			},
			// ...(isAdmin && {
			//   manuel: {
			//     icon: 'mdi mdi-source-branch',
			//     class: 'bg-success',
			//     label: "change branch",
			//     onClick: (data) => {
			//       setShow(data)
			//     }
			//   }
			// })
		},
	});

	const handleGetFullExcel = async () => {
		const workbook = XLSX.utils.book_new();

		const callback = [handleGetDeliveryReportWithDate, handleGetDetailedOrdersWithDate, handleGetStatisticHotels, handleGetFullDataWithDate];

		for (let i = 0; i < callback.length; i++) {
			const curr = callback[i];

			const result = await curr({ callbackData: true });
			console.log("* * * handleGetFullExcel :", { result });
			let apiData = result?.data;
			const head = result?.head;
			const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });
			const range = XLSX.utils.decode_range(worksheet["!ref"]);
			range.e["c"] = head.length - 1;
			worksheet["!ref"] = XLSX.utils.encode_range(range);
			worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
			worksheet["!cols"] = result?.cols;
			// let limitedString = y?.hotel?.length > 30 ? y?.hotel?.substring?.(0, 30) : y?.hotel;
			XLSX.utils.book_append_sheet(workbook, worksheet, result?.name);
		}

		// x?.data?.map((y) => {
		// 	let apiData = y?.rooms?.map((z) => ({
		// 		[t("rooms-scans-orders")]: z?.roomNumber,
		// 		[t("scan-count")]: z?.quantity,
		// 		[t("orderCount")]: z?.orderCount,
		// 	}));
		// 	const head = [t("rooms-scans-orders"), t("scan-count"), t("orderCount")];
		// 	const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });
		// 	const range = XLSX.utils.decode_range(worksheet["!ref"]);
		// 	range.e["c"] = head.length - 1;
		// 	worksheet["!ref"] = XLSX.utils.encode_range(range);
		// 	worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		// 	worksheet["!cols"] = cols;
		// 	let limitedString = y?.hotel?.length > 30 ? y?.hotel?.substring?.(0, 30) : y?.hotel;
		// 	XLSX.utils.book_append_sheet(workbook, worksheet, limitedString);
		// });

		XLSX.writeFile(
			workbook,
			`full-statistic-from-${QueryString.from || "now"}-to-${QueryString.to || "now"}` +
				"_" +
				adminState?.data?.name +
				"_" +
				adminState?.data?.lastName +
				".xlsx"
		);
	};
	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");

	return (
		<div>
			<ShowModal
				{...{
					setModal: setShowInfoModal,
					show: showInfoModal,
					isBranch,
					isHotel,
					isAdmin,
					onClick: clickAction,
					permissions,
					validPermission,
				}}
			/>

			<NoteForStatusCancel
				{...{
					setModal: (x) => setModalStatusCancel((p) => ({ ...p, show: x })),
					show: modalStatusCancel?.show,
					onSubmit: (x) => actionsOperation({ ...modalStatusCancel, note: x }),
				}}
			/>
			<Modal
				// size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={showModal}
				onHide={() => setShowModal(false)}
				style={{ zIndex: 9999999999, maxWidth: 500, margin: "auto" }}>
				<Modal.Header closeButton>
					<Modal.Title>Order Owner Courier</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(onWayStatusAction)} id={"form-container "} noValidate>
						<DeliveriesSelector
							{...{
								name: fieldNames.delivery,
								register,
								label: "delivery",
								errors,
								control,
							}}
						/>
						<Button block type="success" htmlType="submit" className={`btn btn-success btn-sm`}>
							{t("publish")}
						</Button>
					</form>
				</Modal.Body>
			</Modal>
			<PageTitle title={t(isAdmin ? "authorDetails" : "authorDetails", { author: t(COMPONENT_NAMES[0]) })} breadcrumbs={breadcrumbs} />
			<TableComponent
				{...{
					isHotel,
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: t("search"),

						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					// button: button,
					count: data.total,
					pagination: {
						activePage: page,
						pages: Math.ceil(data.total / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
					status: STATUSES,
					dating:
						isAdmin || true
							? {
									buttons: [
										...(validPermission || permissions?.statistics
											? [
													{
														title: t("statics"),
														onClick: handleGetStatistics,
													},
											  ]
											: []),
										...(validPermission || permissions?.statistics
											? [
													{
														title: t("static-hotels"),
														onClick: handleGetStatisticHotels,
													},
											  ]
											: []),
										...(validPermission || permissions?.statistics
											? [
													{
														title: t("static-rooms"),
														onClick: handleGetStatisticRooms,
													},
											  ]
											: []),
										...(validPermission || permissions?.statistics
											? [
													{
														title: t("staticsDetailed"),
														onClick: handleGetStatisticsV2,
													},
											  ]
											: []),
										...(validPermission || statisticsPermissions?.list
											? [
													{
														title: t("static-orders"),
														onClick: handleGetFullDataWithDate,
													},
											  ]
											: []),
										...(validPermission || permissions?.products
											? [
													{
														title: t("order-product-report"),
														onClick: handleGetDetailedOrdersWithDate,
													},
											  ]
											: []),
										...(validPermission || permissions?.deliveryStatistics
											? [
													{
														title: t("delivery-report"),
														onClick: handleGetDeliveryReportWithDate,
													},
											  ]
											: []),
										...(validPermission || permissions?.deliveryStatistics
											? [
													{
														title: t("full"),
														onClick: handleGetFullExcel,
													},
											  ]
											: []),
									],
									// button: {
									//   onClick: handleGetStatistics,
									// },
									// buttonFull: {
									//   onClick: handleGetFullDataWithDate,
									// }
							  }
							: true,
				}}
			/>

			<BranchModal {...{ show, setShow, refetchKey: `${API_NAME}_get` }} />
		</div>
	);
};

export default List;
