import PageTitle from "components/PageTitle";
import TableComponent from "components/TableComponent/index";
import { STATUSES } from "enumerations";
import axiosClient from "lib/axiosConfig";
import toastify from "lib/toastify";
import api from "operations/network/api";
import routes from "operations/routing/routes";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import actionBreadcrumbs from "redux/store/breadcrumbs/action";
import swal from "sweetalert";
import getDataInObjectDepth from "utils/getDataInObject";
import { useMutationCustom } from "utils/useMutationCustom";
import { useQueryCustom } from "utils/useQueryCustom";
import useQueryString from "utils/useQueryString";
import { API_NAME, COMPONENT_NAMES, ROUTE_NAME, PERMISSION } from "../enum";
import { tableData } from "./tableInfo";
import { useSelector } from "react-redux";
import BoxWithLabelValue from "../../../components/BoxWithLabelValue";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import FinanceModal from "containers/Finance/component/GalleryModal";
import isoToDate from "utils/isoToDate";
import { changePriceFormat } from "utils/calculators";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { FINANCE_DATE } from "containers/Finance/enum";
import FinanceDatingComponent from "containers/Finance/FinanceDatingComponent";

const List = () => {
	const [checkList, setCheckList] = useState();
	// ---------------------------------------------- new route
	const newRoute = useMemo(() => getDataInObjectDepth(ROUTE_NAME, routes), []);

	// ---------------------------------------------- Constance
	const adminState = useSelector((state) => state.admin);
	const validPermission = adminState?.data?.permissions === true;
	const permissions = validPermission || adminState?.data?.permissions?.[PERMISSION];
	const isBranch = adminState.data.userType === "branch";
	const isHotel = adminState.data.userType === "hotel";
	const isAdmin = adminState.data.userType?.includes("admin");
	// let { years } = useParams();
	// let defaultYears = SUPPORT_DATE[1];
	// years = years || defaultYears;

	let { years } = useParams();

	let defaultYears = FINANCE_DATE[1];

	years = years || defaultYears;

	let d = moment();
	if (years) d.year(years);
	const minMonthDate = new Date(d.startOf("month"));
	const maxMonthDate = new Date(d.endOf("month"));
	const QueryString = useQueryString({ sort: "desc", from: minMonthDate, to: maxMonthDate });

	// let d = new Date();
	// let from = new Date(d.setHours(0, 0, 0, 0));
	// let to = new Date(d.setHours(23, 59, 59, 999));
	// const QueryString = useQueryString({ sort: "desc", from, to });
	const { page, limit } = QueryString;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	// let minDate = new Date(years + "-01-01");
	// let maxDate = new Date(years + "-12-31");
	// ---------------------------------------------- fetching functions
	const getting = async () =>
		await axiosClient().get(api[API_NAME].list, {
			params: { ...QueryString },
		});
	const changeStatus = async (params) => {
		console.log(params, "changeStatus");
		await axiosClient().patch(api[API_NAME].changeStatus, params);
	};

	const deleting = async (params) => {
		await axiosClient().delete(api[API_NAME].delete + "/" + params?.id);
	};
	// ------------------------------------------------------- query Get
	const {
		data: { data = {} } = {},
		error,
		isError,
		isLoading,
		refetch,
	} = useQueryCustom({
		name: `${API_NAME}_get`,
		url: getting,
		params: { ...QueryString },
		enabled: !!(validPermission || permissions?.list),
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessDeleteMutation = () => {
		toastify.success({ title: "success" });
	};

	const deleteMutation = useMutationCustom({
		name: `${API_NAME}_delete`,
		url: deleting,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessDeleteMutation,
	});
	// ---------------------------------------------- mutation Change Status
	const onSuccessChangeStatusMutation = () => {
		toastify.success({ title: "success" });
	};

	const changeStatusMutation = useMutationCustom({
		name: `${API_NAME}_changeStatus`,
		url: changeStatus,
		invalidQuery: `${API_NAME}_get`,
		onSuccess: onSuccessChangeStatusMutation,
	});
	// ---------------------------------------------- logs
	console.log({ data, error, isError, isLoading });
	// ---------------------------------------------- action click table

	const actionsOperation = ({ data, status }) => {
		swal({
			title: "Are you sure?",
			text: `You want to ${status?.label?.toLowerCase()} this ${COMPONENT_NAMES[0]}`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willBe) => {
			if (willBe) {
				if (status.label === "delete") deleteMutation.mutate({ id: data });
				else changeStatusMutation.mutate({ ids: data, isActive: status.value });
			}
		});
	};

	const clickAction = ({ data, status }) => {
		const newData = data.map((x) => x.id);
		actionsOperation({ data: newData, status });
	};

	const handleAction = (status) => {
		const data = Object.keys(checkList);
		console.log({ data, status });
		actionsOperation({ data, status: status });
	};
	const exportToCSV = ({ apiData, fileName, head, cols }) => {
		const workbook = XLSX.utils.book_new();
		// const myHeader = ["id","name","location"];
		const worksheet = XLSX.utils.json_to_sheet(apiData, { header: head });

		const range = XLSX.utils.decode_range(worksheet["!ref"]);
		range.e["c"] = head.length - 1;
		worksheet["!ref"] = XLSX.utils.encode_range(range);
		worksheet["!autofilter"] = { ref: `A1:${String.fromCharCode(65 + head.length - 1)}1` };
		worksheet["!cols"] = cols;

		XLSX.utils.book_append_sheet(workbook, worksheet, "tab1");
		XLSX.writeFile(workbook, fileName + ".xlsx");

		// const ws = XLSX.utils.json_to_sheet(apiData);
		// /* custom headers */
		// XLSX.utils.sheet_add_aoa(ws, [head], { origin: "A1", });
		// const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		// const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		// const data = new Blob([excelBuffer], { type: fileType });
		// FileSaver.saveAs(data, fileName + fileExtension);
	};

	const handleGetFullDataWithDate = () => {
		let cols = [{ wch: 50 }, { wch: 15 }, { wch: 20 }, { wch: 20 }];
		axiosClient()
			.get(api[API_NAME].list, {
				params: { ...QueryString, limit: 0, page: 1 },
			})
			.then((x) => {
				console.log({ x });
				let head = [t("name"), t("orderNumber"), t("commission"), t("price")];
				let data = [];
				// , t("createdAt")

				if (isAdmin) {
					cols = [{ wch: 30 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 25 }];
					head = [t("name"), t("region"), t("roomCount"), t("commission"), t("orderCount"), t("totalAmount"), t("totalCommission"), t("date"), t("clock")];

					data = x?.data?.result?.map((y) => {
						const date = moment(y?.createdAt);

						return {
							[t("name")]: y?.hotel?.name,
							[t("commission")]: y?.hotel?.commission,
							[t("region")]: y?.region?.name,
							[t("roomCount")]: y?.hotel?.roomCount,
							[t("orderCount")]: y?.orderCount,
							[t("totalAmount")]: y?.totalAmount,
							[t("totalCommission")]: y?.totalCommission,
							[t("date")]: date.format("YYYY-MM-DD"),
							[t("clock")]: date.format("HH:mm:ss"),
						};
					});
				} else {
					data = x?.data?.result?.map((y) => ({
						[t("name")]: y?.products?.map((x) => x.name)?.join(" , "),
						[t("orderNumber")]: y?.orderNumber,
						[t("commission")]: y?.totalCommission,
						[t("price")]: y?.totalPrice,
						// [t("createdAt")]: y?.createdAt,
					}));
				}
				console.log("data =====>", data);

				exportToCSV({ apiData: data, fileName: "allFinance", head, cols });
			})
			.catch((err) => {
				console.log({ err });
			});
	};
	// ---------------------------------------------- breadcrumbs
	const breadcrumbs = [
		{ title: "Dashboard", link: routes.dashboard.base },
		{ title: t(COMPONENT_NAMES[0]), link: newRoute.base },
		{ title: "Details" },
	];
	// ---------------------------------------------- EFFECTS
	useEffect(() => {
		dispatch(actionBreadcrumbs.set(breadcrumbs));
	}, []);

	// const handleShowModal = () => setShowModal(true)
	// const handleHideModal = () => setShowModal(false)

	const tableOptions = tableData({
		isBranch,
		isHotel,
		isAdmin,
		onClick: clickAction,
		newRoute,
		active: {
			// manuel: {
			//   icon: 'mdi mdi-source-branch',
			//   class: 'bg-success',
			//   label: "change branch",
			//   onClick: (data) => {
			//     setShowModal(data)
			//   }
			// }
		},
	});

	const headButtonData = [
		{
			label: "Total Progress Payment",
			value: data?.totalCommission && "£ " + changePriceFormat(data?.totalCommission, 2),
		},
		{
			label: "Total Paid",
			value: "£ " + changePriceFormat(data?.totalPaid && data?.totalPaid, 2),
		},
		{
			label: "Total Remaining",
			value: data?.totalPaid && "£ " + changePriceFormat(data?.totalCommission - data?.totalPaid, 2),
		},
		{
			label: "Most Recent Request Date",
			value: (
				<ul>
					{data?.nextPaymentDates?.map((x) => {
						return (
							<li className="">
								<div className="nowrap">
									{x.name} {" : "}
								</div>
								<div>{isoToDate(data?.nextPaymentDate)}</div>
							</li>
						);
					})}
				</ul>
			),
		},
	];

	const [showModal, setShowModal] = useState(false);
	const handleGalleryModal = () => {
		setShowModal(!showModal);
	};

	const button = { title: "Request Payment", onClick: handleGalleryModal };
	//  ----------------------------------------- LOGS ----------------------------------------- //
	console.log({ checkList, data }, "checkList");

	return (
		<div>
			{/* <div className="text-center">
        <ButtonGroup style={{
          width: "50%"
        }} aria-label="Basic example">
          {
            // handleChangeDate
            SUPPORT_DATE?.map((x) => (
              <Button style={{
                flex: 1
              }} onClick={() => {
                navigate({
                  pathname: newRoute.base + newRoute.list + "/" + x,
                });
              }} variant={years == x ? "primary" : "secondary"}>{x}</Button>

            ))
          }
        </ButtonGroup>
      </div> */}

			<PageTitle
				// title={t(isAdmin ? "authorDetails" : "authorDetails", { author: t("financial") })}
				title={t("authorDetails", { author: !isAdmin ? t("financial") : t(COMPONENT_NAMES[0]) })}
				breadcrumbs={breadcrumbs}
				// buttons={buttons}
				button={!isAdmin && button}
			/>
			<FinanceModal {...{ showModal, handleModal: handleGalleryModal, isGalleryDisabled: true }} />
			{!isAdmin ? (
				<Row className="my-5">
					{headButtonData?.map((x) => {
						return (
							<Col xs={3}>
								{" "}
								<BoxWithLabelValue {...x} />
							</Col>
						);
					})}
				</Row>
			) : (
				""
			)}
			<TableComponent
				{...{
					QueryString,
					data: data?.result,
					tableOptions,
					loading: isLoading,
					search: {
						placeholder: t("search"),
						defaultValue: QueryString.search_text,
					},
					title: `${COMPONENT_NAMES[1]}`,
					button: button,
					count: data.total,
					pagination: {
						activePage: page,
						pages: Math.ceil(data.total / limit),
						limited: 2,
					},
					checkbox: {
						state: checkList,
						setState: setCheckList,
					},
					actions: {
						onClick: handleAction,
					},
					status: STATUSES,
					dating:
						validPermission || permissions?.list
							? {
									buttons: [
										// {
										//   title: t("statics"),
										//   onClick: handleGetStatistics,
										// },
										{
											title: !isAdmin ? t("financial") : t("finance"),
											onClick: handleGetFullDataWithDate,
										},
									],
									// props: {
									// 	showMonthYearPicker: true,
									// 	dateFormat: "MMMM d, yyyy",
									// },
									component: <FinanceDatingComponent {...{ QueryString, baseUrl: routes.financeHotels.base + routes.financeHotels.list }} />,
							  }
							: true,
				}}
			/>
			{/* <BranchModal  {...{ show: showModal, setShow: setShowModal, refetchKey: `${API_NAME}_get` }} /> */}
		</div>
	);
};

export default List;
